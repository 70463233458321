import React, { useEffect, useState } from "react";
import { Auth, I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
import ReCaptcha from "../../components/generic/captcha";
import { Authenticator, CheckboxField, ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
I18n.putVocabularies(translations);

const colors = {
    primary: { value: '#9146FF' },  // Más oscuro
    secondary: { value: '#A66BFF' },   // Un poco más claro
    black: { value: '#000000' },
    white: { value: '#FFFFFF' },
    red: { value: '#FF0000' },    // Rojo
    green: { value: '#00FF00' },  // Verde
    yellow: { value: '#FFFF00' },

};
const theme = {
    name: '',
    tokens: {
        colors: {
            background: {
                primary: colors.white,
                secondary: colors.white,
            },
            font: {
                interactive: colors.primary,
            },
            brand: {
                primary: {
                    '10': colors.white,
                    '80': colors.primary,
                    '90': colors.black,
                    '100': colors.primary,
                },
            },
        },
        components: {
            container: {
                borderRadius: '10px',
            },
            tabs: {
                item: {
                    _focus: {
                        color: colors.primary,
                    },
                    _hover: {
                        color: colors.black,
                    },
                    _active: {
                        color: colors.primary,
                    },
                },
            },
        },
    },
};

const CognitoLanding = () => {
    const { t } = useTranslation();
    const [captchaOk, setCaptchaOk] = useState(false);
    const location = useLocation();
    const [user, setUser] = useState(null);
    const history = useHistory();

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(currentUser => setUser(currentUser))
            .catch(() => console.log("Not signed in"));
        I18n.setLanguage(window.navigator.language.split('-')[0]);
    }, [])

    useEffect(() => {
        if (user != null) history.replace("/")
        return () => {
            setUser(null);
        }
    }, [user])

    const formFields = {
        signUp: {
            name: {
                placeholder: t('Enter your Name'),
                label: t('Name'),
                order: 1
            },
            email: {
                order: 2
            },
            password: {
                order: 3
            },
            confirm_password: {
                order: 4
            }
        },
    }

    const CheckboxFieldNews = () => {
        const [checked, setChecked] = useState(false);
        return (
            <CheckboxField
                name="custom:ReceiveNews"
                value={checked}
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                label={t("I want to receive Picblink news")}
            />
        );
    };

    return (
        !captchaOk
            ? <><ReCaptcha action="SIGNUP" callback={setCaptchaOk} />
                <div className="login">
                    <div className="login-content">
                        <div className="image logo-login">
                            <img
                                src="https://rockthesportv2.blob.core.windows.net/configuracion/mockups/picblink/images/logo-pos.svg"
                                alt="Picblink" className="logo" />
                        </div>
                        <center><progress className="progress is-large is-primary"></progress></center>
                    </div>
                </div></>
            : <div className="login">
                <div className="login-content">
                    <div className="image logo-login">
                        <img
                            src="https://rockthesportv2.blob.core.windows.net/configuracion/mockups/picblink/images/logo-pos.svg"
                            alt="Picblink" className="logo" />
                    </div>
                    <br></br>
                    <ThemeProvider theme={theme}>
                        <Authenticator
                            initialState={location.pathname.slice(1)}
                            formFields={formFields}
                            loginMechanisms={['email']}
                            socialProviders={['google']}
                            signUpAttributes={['name']}
                            components={{
                                SignUp: {
                                    FormFields() {
                                        return (
                                            <>
                                                <Authenticator.SignUp.FormFields />
                                                <div style={{ height: 2 }}></div>
                                                <CheckboxFieldNews />
                                            </>
                                        );
                                    },
                                },
                            }}
                        >
                            <center><button className="button is-rounded" onClick={() => Auth.signOut()}>{t('nav.menu.signOut')}</button></center>
                        </Authenticator>
                    </ThemeProvider>
                    <br></br>
                    <center><label>{t('By signing up you agree to the')}<br></br> <a href="https://picblink.com/picblink-legal-es/">{t('Terms of Use')}</a> {t('and')} <a href="https://picblink.com/picblink-legal-es/">{t('Privacy Policy')}</a></label></center>
                </div>
            </div>
    );

};


export default CognitoLanding;